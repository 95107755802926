export const BSC = 56
export const ARBITRUM = 42161
export const AVALANCHE = 43114
export const FANTOM = 250

export const addresses = {
    [BSC]: {
        GMX: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
        BNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        RewardReader: '0x034CDAF0A39Fe945f78aF5d8a916646429d6Fb96',
        GLP: '0x115B0C8470A873F08B1AFE6C27Ca12c2cB1DeA5c',
        GlpManager: '0x5C522523d9d688D4e7D5D2974FA6FC46E3AF5b43'
    },

    [ARBITRUM]: {
        GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        BTC: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        ETH: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        RewardReader: '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
        GLP: '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
        GlpManager: '0x321F653eED006AD1C29D174e17d96351BDe22649'
    },

    [AVALANCHE]: {
        GMX: '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
        AVAX: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        ETH: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
        BTC: '0x50b7545627a5162f82a992c33b87adc75187b218',
        RewardReader: '0x04Fc11Bd28763872d143637a7c768bD96E44c1b6',
        GLP: '0x01234181085565ed162a948b6a5e88758CD7c7b8',
        GlpManager: '0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F'
    },

    [FANTOM]: {
        GMX: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
        FTM: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
        ETH: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
        BTC: '0x321162cd933e2be498cd2267a90534a804051b11',
        BOO: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
        RewardReader: '0x8F437d76067e30921879F29CE863362920C10678',
        GLP: '0x447bfD0947846Eb1d7C54069D7f7457a54793447',
        GlpManager: '0xAD34B2ec248ad299fc1DDdD7E1eD5A63A6951C57'
    }
}

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
